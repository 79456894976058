<template>
  <div class="aviso">
    <h1>AVISO DE PRIVACIDAD DE DATOS PERSONALES</h1>
    <br />
    <ol>
      <b><li>Responsable de la protección de sus datos personales</li></b
      ><br />
      <p>
        Sohin Soluciones Hospitalarias Integrales S.A.P.I. de C.V. (“La
        Sociedad), con domicilio en Guillermo González Camarena 1450, Piso 2,
        Ciudad Santa Fe, CP 01210, Ciudad de México, (el “Domicilio”), es
        responsable del tratamiento de sus datos personales.
      </p>
      <br />
      <b
        ><li>
          ¿Para qué fines recabamos y utilizamos sus datos personales?
        </li></b
      ><br />
      <p>
        Sus datos personales serán utilizados para las siguientes finalidades:
      </p>
      <br />
      <ul class="circle">
        <li>
          Para la creación, estudio, análisis, actualización y conservación de
          expediente clínico.
        </li>
        <li>Para proveer servicios de asistencia personal.</li>
        <li>
          Coordinar la gestión de servicios o compra de productos solicitados
          por usted.
        </li>
        <li>
          Articular la entrega y aplicación de tratamientos farmacológicos a los
          pacientes en su domicilio, consultorio del médico tratante, centro de
          infusión o institución hospitalaria,
        </li>
        <li>Gestionar y/o realizar análisis genéticos y clínicos.</li>
        <li>
          Procesar reclamaciones de pago o reembolso ante instituciones
          aseguradoras o entidades que cubran o reembolsen el costo de servicios
          médicos.
        </li>
        <li>
          Compartir, con su compañía aseguradora, los resultados médicos de los
          estudios que le realicemos.
        </li>
        <li>
          Realizar labores de facturación, aclaraciones y seguimientos de cobro.
        </li>
      </ul>
      <br />
      <p>
        De manera adicional, utilizaremos su información personal para las
        siguientes finalidades que no son necesarias para el servicio
        solicitado, pero que nos permiten y facilitan brindarle una mejor
        atención:
      </p>
      <br />
      <ul>
        <li>Evaluar la calidad del servicio.</li>
        <li>Enviar promociones.</li>
        <li>
          nformar sobre cambios o nuevos servicios que estén relacionados con el
          contratado o adquirido por el cliente.
        </li>
      </ul>
      <br />
      <b
        ><li>
          Mecanismo para manifestar la negativa para finalidades secundarias.
        </li></b
      ><br />
      <p>
        En caso de que no desee que sus datos personales sean tratados para
        estos fines adicionales favor de manifestarlo mediante correo
        electrónico a avisodeprivacidad@sohin.mx<br /><br />
        La negativa para el uso de sus datos personales para estas finalidades
        no podrá ser un motivo para que le neguemos los servicios y productos
        que solicita o contrata con nosotros.
      </p>
      <br />
      <b><li>¿Qué datos personales obtenemos y de dónde?</li></b
      ><br />
      <p>
        Para llevar a cabo las finalidades descritas en el presente aviso de
        privacidad, utilizaremos los siguientes datos:<br /><br />
        Datos de identificación Datos de contacto Datos sobre características
        físicas Datos de salud<br /><br />
        Estos datos los obtenemos de las siguientes formas:<br /><br />
        1. Directamente de usted, a través de nuestro sitio de Internet,
        comunicaciones electrónicas o comunicaciones por teléfono.<br />
        2. A través de terceros facultados para compartir los datos personales
        (en particular aseguradoras), en cuyo caso los trataremos de acuerdo con
        las disposiciones contenidas en el aviso de privacidad de origen.
      </p>
      <br />
      <b><li>¿Qué datos personales sensibles utilizaremos?</li></b
      ><br />
      <p>
        Además de los datos personales mencionados anteriormente, para las
        finalidades informadas en el presente aviso de privacidad utilizaremos
        los siguientes datos que pudieran revelar el estado de salud presente o
        futuro del titular, así como su información genética, mismos que son
        considerados como sensibles, que requieren de especial protección. Nos
        comprometemos a que los mismos serán tratados bajo todas las medidas de
        seguridad que se encuentren a nuestro alcance para mantener su
        confidencialidad; para efectos de claridad, dichas medidas serán las
        mismas que las que la Sociedad mantiene para el manejo de su información
        confidencial.<br /><br />
        No obstante lo anterior, y de acuerdo con lo establecido en el artículo
        10 fracción VI de la Ley en comento, no será necesario el consentimiento
        para el tratamiento de los datos personales cuando sean indispensables
        para la atención médica, la prevención, diagnóstico, la prestación de
        asistencia sanitaria, tratamientos médicos o la gestión de servicios
        sanitarios, mientras el titular no esté en condiciones de otorgar el
        consentimiento en términos que establece la Ley general de Salud y demás
        disposiciones jurídicas aplicables y que dicho tratamiento de datos se
        realice por una persona sujeta al secreto profesional u obligación
        equivalente.
      </p>
      <br />
      <b><li>Transferencia de los datos personales</li></b
      ><br />
      <p>
        Los datos personales que sean recabados por “La Sociedad”, no serán
        vendidos, sin embargo podrán ser tratados por personas distintas a “La
        Sociedad” y sus encargados. En ese sentido, su información puede ser
        compartida con terceros nacionales o extranjeros relacionados con la
        prestación de nuestros servicios que de manera enunciativa mas no
        limitativa incluyen e la empresa aseguradora con la que tiene contratado
        su servicio de gastos médicos mayores así como con laboratorios
        farmacéuticos. Si usted no manifiesta su negativa para dichas
        transferencias, entenderemos que nos lo ha otorgado. Nos comprometemos a
        no transferir su información personal a terceros ajenos a la prestación
        de nuestros servicios sin su consentimiento, salvo las excepciones
        previstas en el artículo 37 de la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares.
      </p>
      <br />
      <b><li>Consentimiento para la transferencia.</li></b
      ><br />
      <p>
        En caso de que la transferencia de datos con terceros verse sobre datos
        sensibles, le informamos que requerimos obtener su consentimiento. Si
        usted no manifiesta su negativa para dichas transferencias, entenderemos
        que nos lo ha otorgado.<br /><br />
        En caso de que no autorice la transferencia de sus datos, favor de
        manifestarlo al correo: avisodeprivacidad@sohin.mx
      </p>
      <br />
      <b
        ><li>
          Medios para ejercer sus derechos ARCO (acceso, ratificación,
          cancelación u oposición) al tratamiento de sus datos personales
        </li></b
      ><br />
      <p>
        Usted tiene derecho a conocer qué datos personales tenemos de usted,
        para qué los utilizamos y las condiciones del uso que les damos
        (Acceso). Asimismo, es su derecho solicitar la corrección de su
        información personal en caso de que esté desactualizada, sea inexacta o
        incompleta (Rectificación); que la eliminemos de nuestros registros o
        bases de datos cuando considere que la misma no está siendo utilizada
        conforme a los principios, deberes y obligaciones previstas en la
        normativa (Cancelación); así como oponerse al uso de sus datos
        personales para fines específicos (Oposición). Estos derechos se conocen
        como derechos ARCO.<br /><br />
        Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
        presentar la solicitud respectiva en Guillermo González Camarena 1450,
        Piso 2, Ciudad Santa Fe, CP 01210, Ciudad de México, o bien a través de
        correo electrónico enviando la solicitud a:
        avisodeprivacidad@sohin.mx<br /><br />
        Su solicitud deberá contener la siguiente información: : i) su nombre y
        domicilio para darle respuesta; ii) copia de su credencial de elector,
        pasaporte, licencia para conducir, cédula profesional o cualquier otro
        documento que acredite su identidad o bien el documento que acredite la
        personalidad de su representante legal ; iii) contener una descripción
        clara y precisa de los datos personales sobre los que se busca ejercer
        alguno de los derechos de acceso, rectificación, cancelación u oposición
        al tratamiento; iv) cualquier otro elemento o documento que facilite la
        localización de dichos datos personales, y v) cualquier otro requisito
        establecido por la Ley de Protección de Datos, su Reglamento y demás
        disposiciones aplicables.<br /><br />
        Los plazos para atender su solicitud son los siguientes: el responsable
        comunicará al titular, en un plazo máximo de 20 días, contados desde la
        fecha en que se recibió la solicitud, la determinación adoptada, a
        efecto de que, si resulta procedente, se haga efectiva la misma dentro
        de los quince días siguientes a la fecha en que se comunica la
        respuesta. Los plazos antes referidos podrán ser ampliados una sola vez
        por un periodo igual, siempre y cuando así lo justifiquen las
        circunstancias del caso. Para mayor información, favor de comunicarse al
        departamento de privacidad en nuestra dirección o visitar nuestra página
        de Internet.
      </p>
      <br />
      <b
        ><li>
          ¿Cómo puede revocar o limitar su consentimiento para el tratamiento de
          sus datos?
        </li></b
      ><br />
      <p>
        En todo momento y salvo por lo que se señale en la Ley y lo acordado en
        el presente, usted podrá revocar o limitar el consentimiento que nos ha
        otorgado para el tratamiento de sus datos personales, a fin de que
        dejemos de hacer uso de los mismos. Para ello, es necesario que presente
        su solicitud en los términos que se señalan en el numeral anterior.
        Dicha solicitud será resuelta en términos de lo señalado en el inciso
        anterior.<br />
        Asimismo, usted deberá considerar que para ciertos fines, la revocación
        de su consentimiento implicará que no le podamos seguir prestando el
        servicio que nos solicitó, o la conclusión de su relación con nosotros.
      </p>
      <br />
      <b><li>Modificaciones al aviso de privacidad</li></b
      ><br />
      <p>
        Nos reservamos el derecho de efectuar en cualquier momento
        modificaciones o actualizaciones al presente aviso de privacidad, para
        la atención de novedades legislativas, políticas internas o nuevos
        requerimientos para la prestación u ofrecimiento de nuestros servicios o
        productos. Estas modificaciones estarán disponibles al público a través
        de uno o más de los siguientes medios: (i) anuncios visibles en nuestros
        establecimientos o centros de atención a clientes; (ii) trípticos o
        folletos disponibles en nuestros establecimientos o centros de atención
        a clientes; (iii) en nuestra página de Internet; (iv) o se las haremos
        llegar al último correo electrónico que nos haya proporcionado.
      </p>
      <br />
      <b><li>Medidas de seguridad</li></b
      ><br />
      <p>
        “La Sociedad” adoptará todas y cada una de las medidas de seguridad
        administrativas, físicas y técnicas necesarias para salvaguardar su
        información personal de cualquier daño, pérdida, alteración, destrucción
        o el uso, acceso o tratamiento no autorizado, por lo que de igual manera
        y en caso de que un tercero necesite conocer de dicha información “La
        Sociedad” obligará a dichos terceros a cumplir con el presente Aviso de
        Privacidad, así como a adoptar las medidas de seguridad administrativas,
        físicas y técnicas establecidas en la Ley de Protección de Datos.<br /><br />
        “La Sociedad” incluso con posterioridad a la finalización de la relación
        que guarde con el titular de los datos personales, se compromete a
        guardar la confidencialidad de los mismos.
      </p>
      <br />
      <b
        ><li>
          ¿Ante quién puede presentar sus quejas y denuncias por el tratamiento
          indebido de sus datos personales?
        </li></b
      ><br />
      <p>
        Si usted considera que su derecho de protección de datos personales ha
        sido lesionado por alguna conducta de nuestros empleados o de nuestras
        actuaciones o respuestas, presume que en el tratamiento de sus datos
        personales existe alguna violación a las disposiciones previstas en la
        Ley Federal de Protección de Datos Personales en Posesión de los
        Particulares, podrá interponer la queja o denuncia correspondiente ante
        el INAI.
      </p>
      <br />
    </ol>
    <h2>POLÍTICA DE CALIDAD</h2>
    <br />
    <p>
      En SOHIN trabajamos para transformar el modelo de atención de pacientes
      con enfermedades crónico degenerativas catastróficas por ellos estamos
      comprometidos a hacer de la calidad nuestro modo de vida enfocándonos en
      asegurar la satisfacción de nuestros clientes: pacientes, médicos e
      instituciones hospitalarias a partir del cumplimiento de los acuerdos, los
      requisitos aplicables a las unidades de negocio y la mejora continua de
      nuestro SGC mediante la revisión constante de nuestros procesos y
      objetivos.
    </p>
  </div>
</template>

<script>
export default {
  name: "Aviso",
};
</script>

<style>
.aviso {
  background-color: #edf4f5;
  padding: 10% 20%;
  text-align: justify;
}
.circle {
  list-style-type: disc;
}
.aviso h1 {
  color: #6acbd9;
  font-size: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: center;
}
.aviso li,
.aviso p {
  color: #5b5b5b;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}
.aviso h2 {
  color: #333399;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 400px) {
  .aviso {
    background-color: #edf4f5;
    padding: 7% 7% 7% 1%;
  }
  .aviso h1{
    font-size: 28px;
  }
}
</style>