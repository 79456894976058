<template>
  <div class="quienes-component">
    <div class="quienes-component-cont">
      <h1>¿Quiénes <b>somos?</b></h1>
      <p>
        En <span class="background"><b>Genethic Services</b></span> somos
        pioneros en facilitar el diagnóstico genético en México además de ser
        líderes en gestión e implementación de soluciones diagnósticas para la
        salud personalizada y medicina de precisión en cáncer, nutrición y
        bienestar, salud cardiovascular y enfermedades Crónico-degenerativas.
      </p>
      <p>
        <span class="color2"
          >Los estudios genéticos y génomicos son una valiosa herramienta para
          que los profesionales de la salud puedan tomar decisiones con base en
          las características únicas de cada ser humano</span
        >
      </p>
    </div>
    <div class="quienes-component-img">
      <img src="../assets/Assetsx1/Img_001.png" draggable="false" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeQuienesSomos",
};
</script>
