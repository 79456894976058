<template>
  <div>
    <div class="home-baner home-baner1">
      <div class="home-baner-cont home-baner-cont1">
        <p>
          <span class="color"><b>En el mundo</b></span> somos millones de
          personas con un
          <span class="background"> código genético </span>
          <span class="color"><b>que nos hace únicos e irrepetibles.</b></span>
        </p>
        <router-link to="/contacto" @click.native="scrollTop()"
          ><button>Solicita una prueba</button></router-link
        >
      </div>
    </div>
    <div class="quien">
      <div class="quien-menu">
        <div class="quien-menu-cont">
          <div class="quien-menu-cont-animation">
            <div class="quien-menu-cont-bullet"></div>
            <div class="quien-menu-cont-bullet-lineA"></div>
            <div :class="lineDisplayA"></div>
          </div>
          <router-link
            to="/quienes-somos"
            active-class="active-quien"
            @click.native="activeAnimation('a')"
            >¿Quiénes somos?</router-link
          >
          <div class="quien-menu-cont-animation">
            <div class="quien-menu-cont-bullet"></div>
            <div class="quien-menu-cont-bullet-lineB"></div>
            <div :class="lineDisplayB"></div>
          </div>
          <router-link
            to="/que-hacemos"
            active-class="active-quien"
            @click.native="activeAnimation('b')"
            >¿Qué hacemos?</router-link
          >
          <div class="quien-menu-cont-animation">
            <div class="quien-menu-cont-bullet"></div>
            <div class="quien-menu-cont-bullet-lineC"></div>
            <div :class="lineDisplayC"></div>
          </div>
          <router-link
            to="/como-funciona"
            active-class="active-quien"
            @click.native="activeAnimation('c')"
            >¿Cómo funciona?</router-link
          >
        </div>
      </div>
      <div class="quien-vista">
        <router-view></router-view>
      </div>
    </div>
    <div class="home-video">
      <iframe
        src="https://www.youtube.com/embed/vG5IPDkVAxQ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div class="home-video-img">
        <img src="../assets/Assetsx1/Back001.png" draggable="false" />
      </div>
    </div>
    <div class="la_respuesta">
      <div class="la_respuesta-img1">
        <img src="../assets/Assetsx1/Textura01.png" draggable="false" />
      </div>
      <div class="la_respuesta-img2">
        <img src="../assets/Assetsx1/Textura002.png" draggable="false" />
      </div>
      <div class="la_respuesta-cont">
        <!-- <p><b>"</b></p>
        <p>
          La respuesta está<br /><span class="color"><b>en los genes.</b></span
          ><b>"</b>
        </p> -->
        <img src="../assets/Assetsx1/Frase001.png" draggable="false" />
      </div>
    </div>
    <div class="nuestro_adn">
      <div class="nuestro_adn-title">
        <p><span class="background">Nuestro </span><b>ADN</b></p>
      </div>
      <div class="nuestro_adn-img">
        <img src="../assets/Assetsx1/ADN_info.png" draggable="false" />
      </div>
      <div class="nuestro_adn-img1">
        <img src="../assets/Assetsx1/MedioCirculo01.png" draggable="false" />
      </div>
    </div>
    <div class="salvamos_vidas">
      <div class="salvamos_vidas-img_cont">
        <img src="../assets/Assetsx1/IMG002.png" draggable="false" />
        <div class="salvamos_vidas-img_cont1">
          <img src="../assets/Assetsx1/Img_003.png" draggable="false" />
        </div>
      </div>
      <div class="salvamos_vidas-cont">
        <div class="salvamos_vidas-cont1">
          <img src="../assets/Assetsx1/Bullet.png" draggable="false" />
          <p>
            <b>En Genethic Services</b> entendemos el dolor y la incertidumbre
            que causa en los pacientes y sus familias estas enfermedades tan
            complejas, de ahí nuestro compromiso en ayudarles a disminuir esta
            carga física y emocional.
          </p>
        </div>
        <div class="salvamos_vidas-cont2">
          <h1>Salvamos vidas</h1>
          <p>
            de manera anticipada gracias a los diagnósticos que permiten
            predecir enfermedades terminales.
          </p>
        </div>
      </div>
    </div>
    <div class="testimonios">
      <div class="testimonios-img1">
        <img src="../assets/Assetsx1/Circulo001.png" draggable="false" />
      </div>
      <div class="testimonios-title">
        <div class="testimonios-title-img2">
          <img src="../assets/Assetsx1/ADN_001.png" draggable="false" />
        </div>
        <p><b>Testimonios</b></p>
      </div>
      <div class="testimonios-video">
        <iframe
          :src="slideHome"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="testimonios-carousel">
        <carousel
          :items="4"
          :margin="300"
          :loop="true"
          :autoplay="true"
          :dots="false"
          :nav="true"
          :navText="[prevTestimonios, nextTestimonios]"
          :responsive="{
            0: { margin: 90, nav: true },
            400: { margin: 115, nav: true },
            700: { margin: 170, nav: true },
            1200: { margin: 300, nav: true },
            2000: { margin: 350, nav: true },
          }"
        >
          <div
            class="testimonios-item"
            v-for="testimoniosItem in testimoniosItems"
            :key="testimoniosItem"
          >
            <img
              :src="testimoniosItem.img"
              @click="changeSlideHome(testimoniosItem.link)"
            />
          </div>
          <!-- <div class="testimonios-item">
            <img src="https://i.ytimg.com/vi_webp/ODx_98uvmhg/maxresdefault.webp" @click="changeSlideHome('https://www.youtube.com/embed/ODx_98uvmhg')">
          </div>
          <div class="testimonios-item">
            <img src="https://i.ytimg.com/vi_webp/8NT0ltJIzzM/maxresdefault.webp" @click="changeSlideHome('https://www.youtube.com/embed/8NT0ltJIzzM')">
          </div>
          <div class="testimonios-item">
            <img src="https://i.ytimg.com/vi_webp/ODx_98uvmhg/maxresdefault.webp" @click="changeSlideHome('https://www.youtube.com/embed/ODx_98uvmhg')">
          </div>
          <div class="testimonios-item">
            <img src="https://i.ytimg.com/vi_webp/8NT0ltJIzzM/maxresdefault.webp" @click="changeSlideHome('https://www.youtube.com/embed/8NT0ltJIzzM')">
          </div>
          <div class="testimonios-item">
            <img src="https://i.ytimg.com/vi_webp/ODx_98uvmhg/maxresdefault.webp" @click="changeSlideHome('https://www.youtube.com/embed/ODx_98uvmhg')">
          </div>
          <div class="testimonios-item">
            <img src="https://i.ytimg.com/vi_webp/8NT0ltJIzzM/maxresdefault.webp" @click="changeSlideHome('https://www.youtube.com/embed/8NT0ltJIzzM')">
          </div> -->
        </carousel>
      </div>
      <div class="testimonios-cuentanos">
        <div class="testimonios-cuentanos-title">
          <p>
            Cuéntanos tu historia,
            <b
              >¡Ayúdanos a<br />transformar la manera
              <span class="background">de tratar el cáncer!</span></b
            >
          </p>
          <div class="testimonios-cuentanos-title-img3">
            <img src="../assets/Assetsx1/Adn002.png" draggable="false" />
          </div>
        </div>
        <div class="testimonios-cuentanos-button">
          <router-link to="/contacto" @click.native="scrollTop()"
            ><button>Solicita una prueba</button></router-link
          >
        </div>
        <div class="footer-logos">
          <div>
            <img src="../assets/Assetsx1/cuidarte.png" draggable="false" />
          </div>
          <div>
            <img src="../assets/Assetsx1/Sohin.png" draggable="false" />
          </div>
          <div>
            <img src="../assets/Assetsx1/Concierge.png" draggable="false" />
          </div>
          <div>
            <img
              src="../assets/Assetsx1/HARMONY-HEALTH-VERTICAL.png"
              draggable="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  name: "Home",
  components: { carousel },
  data() {
    return {
      lineDisplayA: "quien-menu-cont-bullet-lineAOff",
      lineDisplayB: "quien-menu-cont-bullet-lineBOff",
      lineDisplayC: "quien-menu-cont-bullet-lineCOff",
      testimoniosItems: [
        {
          img: "https://i.ytimg.com/vi_webp/ODx_98uvmhg/maxresdefault.webp",
          link: "https://www.youtube.com/embed/ODx_98uvmhg",
        },
        {
          img: "https://i.ytimg.com/vi_webp/8NT0ltJIzzM/maxresdefault.webp",
          link: "https://www.youtube.com/embed/8NT0ltJIzzM",
        },
        {
          img: "https://i.ytimg.com/vi_webp/ODx_98uvmhg/maxresdefault.webp",
          link: "https://www.youtube.com/embed/ODx_98uvmhg",
        },
        {
          img: "https://i.ytimg.com/vi_webp/8NT0ltJIzzM/maxresdefault.webp",
          link: "https://www.youtube.com/embed/8NT0ltJIzzM",
        },
        {
          img: "https://i.ytimg.com/vi_webp/ODx_98uvmhg/maxresdefault.webp",
          link: "https://www.youtube.com/embed/ODx_98uvmhg",
        },
        {
          img: "https://i.ytimg.com/vi_webp/8NT0ltJIzzM/maxresdefault.webp",
          link: "https://www.youtube.com/embed/8NT0ltJIzzM",
        },
      ],
      prevTestimonios:
        '<i class="fas fa-chevron-left flecha-izquierdaTestimonios"></i>',
      nextTestimonios:
        '<i class="fas fa-chevron-right flecha-derechaTestimonios"></i>',
      slideHome: "https://www.youtube.com/embed/ODx_98uvmhg",
    };
  },
  methods: {
    activeAnimation(line) {
      if (line == "a") {
        this.lineDisplayA = "quien-menu-cont-bullet-lineAOn";
        this.lineDisplayB = "quien-menu-cont-bullet-lineBOff";
        this.lineDisplayC = "quien-menu-cont-bullet-lineCOff";
        setTimeout(() => {
          document.getElementsByClassName(
            "quien-menu-cont-bullet"
          )[1].style.backgroundColor = "#cfd9e2";
          document.getElementsByClassName(
            "quien-menu-cont-bullet"
          )[2].style.backgroundColor = "#cfd9e2";
        }, 300);
        document.getElementsByClassName(
          "quien-menu-cont-bullet"
        )[0].style.backgroundColor = "#134270";
      } else if (line == "b") {
        this.lineDisplayA = "quien-menu-cont-bullet-lineAOff";
        this.lineDisplayB = "quien-menu-cont-bullet-lineBOn";
        this.lineDisplayC = "quien-menu-cont-bullet-lineCOff";
        setTimeout(() => {
          document.getElementsByClassName(
            "quien-menu-cont-bullet"
          )[0].style.backgroundColor = "#cfd9e2";
          document.getElementsByClassName(
            "quien-menu-cont-bullet"
          )[2].style.backgroundColor = "#cfd9e2";
        }, 300);
        document.getElementsByClassName(
          "quien-menu-cont-bullet"
        )[1].style.backgroundColor = "#134270";
      } else if (line == "c") {
        this.lineDisplayA = "quien-menu-cont-bullet-lineAOf";
        this.lineDisplayB = "quien-menu-cont-bullet-lineBOff";
        this.lineDisplayC = "quien-menu-cont-bullet-lineCOn";
        setTimeout(() => {
          document.getElementsByClassName(
            "quien-menu-cont-bullet"
          )[0].style.backgroundColor = "#cfd9e2";
          document.getElementsByClassName(
            "quien-menu-cont-bullet"
          )[1].style.backgroundColor = "#cfd9e2";
        }, 300);
        document.getElementsByClassName(
          "quien-menu-cont-bullet"
        )[2].style.backgroundColor = "#134270";
      }
    },
    changeSlideHome(link) {
      this.slideHome = link;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.quienes-component-img img,
.home-video-img img,
.la_respuesta-img2 img,
.la_respuesta-cont img,
.nuestro_adn-img1 img,
.salvamos_vidas-img_cont1 img,
.testimonios-img1 img,
.testimonios-title-img2 img,
.testimonios-cuentanos-title-img3 img {
  width: 100%;
}
.nuestro_adn-title p,
.testimonios-title p,
.testimonios-cuentanos-title p {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 3.7vw;
}
.home-video iframe,
.testimonios-video iframe {
  width: 55%;
  height: 33vw;
  background-color: #000;
  border-radius: 15px;
}

.home-baner1 {
  height: 46vw;
  background-image: url("../assets/Assetsx1/Header001.png");
  background-repeat: no-repeat;
  background-size: 100% 46vw;
}
.home-baner-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-left: 10%;
  text-align: justify;
  height: 100%;
}
.home-baner-cont p {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 3.3vw;
}
.home-baner-cont button {
  background-color: #1cb7c7;
  color: #fff;
  font-family: "Mulish", sans-serif;
  font-size: 1.4vw;
  border: none;
  border-radius: 7px;
  width: fit-content;
  padding: 1.5% 4%;
  cursor: pointer;
  margin-top: 9%;
}
.quien {
  height: 30vw;
  display: grid;
  grid-template-columns: 20% 70%;
  justify-content: space-around;
  margin: 0 0 2%;
}
.quien-menu {
  padding-left: 12%;
}
.quien-menu-cont {
  background-color: #f3f5f8;
  display: grid;
  grid-template-columns: 20% 70%;
  grid-template-rows: repeat(3, 7vw);
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.quien-menu-cont-animation {
  position: relative;
  width: 100%;
  height: 100%;
}
.quien-menu-cont-bullet {
  background-color: #cfd9e2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  margin: auto;
  width: 1.2vw;
  height: 1.2vw;
  border-radius: 50%;
}
.quien-menu-cont-bullet-lineA {
  background-color: #cfd9e2;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  width: 0.3vw;
  height: 50%;
}
.quien-menu-cont-bullet-lineB {
  background-color: #cfd9e2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0.3vw;
  height: 100%;
}
.quien-menu-cont-bullet-lineC {
  background-color: #cfd9e2;
  position: absolute;
  top: 0;
  bottom: 50%;
  left: 0;
  right: 0;
  margin: auto;
  width: 0.3vw;
  height: 50%;
}

.quien-menu-cont-bullet-lineAOn {
  background-color: #134270;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  width: 0.3vw;
  top: 50%;
  height: 50%;
  animation: activateLineA 0.3s linear;
}
.quien-menu-cont-bullet-lineAOff {
  background-color: #134270;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  width: 0.3vw;
  top: 0%;
  height: 0px;
  animation: desActivateLineA 0.3s linear;
}
.quien-menu-cont-bullet-lineBOn {
  background-color: #134270;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  width: 0.3vw;
  top: 0;
  height: 100%;
  animation: activateLineB 0.3s linear;
}
.quien-menu-cont-bullet-lineBOff {
  background-color: #134270;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  width: 0.3vw;
  top: 0%;
  height: 0px;
  animation: desActivateLineB 0.3s linear;
}
.quien-menu-cont-bullet-lineCOn {
  background-color: #134270;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  width: 0.3vw;
  top: -50%;
  height: 50%;
  animation: activateLineC 0.3s linear;
}
.quien-menu-cont-bullet-lineCOff {
  background-color: #134270;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  width: 0.3vw;
  top: 50%;
  height: 0px;
  animation: desActivateLineC 0.3s linear;
}

@keyframes activateLineA {
  0% {
    top: 0%;
    height: 0px;
  }
  100% {
    top: 50%;
    height: 50%;
  }
}
@keyframes desActivateLineA {
  0% {
    top: 50%;
    height: 50%;
  }
  100% {
    top: 0%;
    height: 0px;
  }
}
@keyframes activateLineB {
  0% {
    height: 0px;
  }
  100% {
    height: 100%;
  }
}
@keyframes desActivateLineB {
  0% {
    height: 100%;
  }
  100% {
    height: 0px;
  }
}
@keyframes activateLineC {
  0% {
    top: 0px;
    height: 0px;
  }
  100% {
    top: -50%;
    height: 50%;
  }
}
@keyframes desActivateLineC {
  0% {
    top: -50%;
    height: 50%;
  }
  100% {
    top: 0px;
    height: 0px;
  }
}

.quien-menu-cont a {
  color: #134270;
  text-decoration: none;
  font-family: "Mulish", sans-serif;
  font-size: 1.2vw;
}
.active-quien {
  font-weight: 700;
}
.quienes-component {
  display: grid;
  grid-template-columns: 45% 50%;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.quienes-component-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.quienes-component-cont h1 {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 3.2vw;
}
.quienes-component-cont p {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 1.1vw;
  text-align: justify;
}
.quienes-component-img {
  display: flex;
  align-items: center;
}
.home-video {
  position: relative;
  height: 44vw;
  box-shadow: 0 1px 20px 0 rgb(31 38 135 / 37%);
  text-align: center;
  padding: 5% 0;
}
.home-video-img {
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
}
.la_respuesta {
  position: relative;
  height: 45vw;
  background-image: url("../assets/Assetsx1/Banner001.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.la_respuesta-img1 {
  position: absolute;
  left: 0;
  bottom: 1%;
  text-align: left;
  width: 15%;
}
.la_respuesta-img2 {
  position: absolute;
  right: 1%;
  bottom: 1%;
  text-align: right;
  width: 45%;
}
.la_respuesta-img1 img {
  width: 95%;
}
.la_respuesta-cont {
    position: absolute;
    top: 10%;
    right: 15%;
    width: 35%;
}
.nuestro_adn {
  position: relative;
  text-align: center;
  padding: 5% 0 12%;
}
.nuestro_adn-title {
  margin-bottom: 7%;
}
.nuestro_adn-img img {
  width: 75%;
}
.nuestro_adn-img1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 12%;
}
.salvamos_vidas {
  background-color: #e7f8f9;
  position: relative;
  display: grid;
  grid-template-columns: 40% 45%;
  justify-content: space-between;
}
.salvamos_vidas-img_cont {
  position: relative;
}
.salvamos_vidas-img_cont img {
  width: 100%;
  height: 100%;
}
.salvamos_vidas-img_cont1 {
  position: absolute;
  left: 50%;
  bottom: 50%;
  width: 70%;
}
.salvamos_vidas-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 7% 10% 7% 0;
}
.salvamos_vidas-cont1 {
  display: flex;
}
.salvamos_vidas-cont1 img {
  margin-right: 5%;
  width: 1.7vw;
}
.salvamos_vidas-cont1 p {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 1.3vw;
  text-align: justify;
}
.salvamos_vidas-cont2 h1 {
  color: #1baabb;
  font-family: "Mulish", sans-serif;
  font-size: 3.7vw;
  text-align: justify;
}
.salvamos_vidas-cont2 p {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 2.3vw;
  text-align: justify;
}
.testimonios {
  position: relative;
  text-align: center;
  padding: 9% 0 0;
}
.testimonios-img1 {
  position: absolute;
  top: 5%;
  left: 0;
  width: 20%;
}
.testimonios-title-img2 {
  text-align: end;
  position: absolute;
  top: -85%;
  right: 0;
  width: 33%;
}
.testimonios-title {
  position: relative;
}
.testimonios-video {
  position: relative;
  text-align: center;
  padding: 5% 0;
}
.testimonios-carousel {
  padding: 5% 3%;
}
.testimonios-item {
  background-color: #134270;
  border-radius: 15px;
  width: 25vw;
  height: 14vw;
  cursor: pointer;
}
.testimonios-item img {
  border-radius: 15px;
  width: 100%;
}
.owl-theme .owl-nav [class*="owl-"] {
  background-color: transparent;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
}
.testimonios-carousel .owl-nav {
  position: absolute;
  top: -50%;
  right: 0%;
  height: fit-content;
}
.flecha-izquierdaTestimonios {
  color: #1cb7c7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.flecha-derechaTestimonios {
  color: #1cb7c7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.testimonios-cuentanos {
  position: relative;
  height: 45vw;
  background-image: url("../assets/Assetsx1/Back002.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10% 0 0 5%;
}
.testimonios-cuentanos-title {
  position: relative;
  text-align: start;
}
.testimonios-cuentanos-title-img3 {
  position: absolute;
  right: 23%;
  top: -75%;
  width: 12%;
}
.testimonios-cuentanos-button button {
  background-color: #1cb7c7;
  color: #fff;
  font-family: "Mulish", sans-serif;
  font-size: 1.5vw;
  border: none;
  border-radius: 7px;
  width: fit-content;
  padding: 0.9% 2.3%;
  cursor: pointer;
  margin-top: 9%;
}
@media (max-width: 400px) {
  .la_respuesta-cont img,
  .nuestro_adn-img img {
    width: 100%;
  }
  .home-video iframe,
  .testimonios-video iframe {
    width: 95%;
    height: 25vh;
  }
  .quienes-component-cont p{
    font-size: 2.7vw;
  }
  .quienes-component-img img,
  .la_respuesta-cont {
    width: 60%;
  }
  .nuestro_adn-title p,
  .testimonios-title p {
    font-size: 6vw;
  }

  .home-video-img,
  .nuestro_adn-img1 {
    display: none;
  }
  .home-baner1 {
    height: 57vw;
    background-image: url("../assets/Assetsx1/HeaderMobile.png");
    background-size: 100% 57vw;
  }
  .home-baner-cont {
    align-items: center;
  }
  .home-baner-cont1 p {
    font-size: 4vw;
    text-align: start;
  }
  .home-baner-cont button {
    font-size: 3.8vw;
    padding: 1% 3.5%;
  }
  .quien {
    height: auto;
    grid-template-columns: 30% 70%;
    justify-content: space-between;
  }

  .quien-menu-cont {
    grid-template-columns: 20% 70%;
    grid-template-rows: repeat(3, 3.5rem);
  }
  .quien-menu-cont a {
    font-size: 3vw;
  }
  .quienes-component {
    grid-template-columns: 90%;
    justify-content: center;
  }
  .quienes-component-cont h1 {
    font-size: 4vw;
  }
  .quienes-component-img {
    justify-content: end;
  }
  .home-video {
    height: auto;
    padding: 10% 0;
  }
  .la_respuesta {
    height: 30vh;
    background-size: 100% 30vh;
  }
  .la_respuesta-img1 {
    position: absolute;
    left: 0;
    bottom: 1%;
    text-align: left;
  }
  .la_respuesta-img2 {
    position: absolute;
    right: 1%;
    bottom: 1%;
    text-align: right;
  }
  .la_respuesta-img1 img {
    width: 50%;
  }
  .la_respuesta-img2 img {
    width: 100%;
  }
  .la_respuesta-img1 {
    width: 30%;
  }
  .la_respuesta-cont{
    right: 0;
  }
  .nuestro_adn-title {
    margin-bottom: 10%;
  }
  .salvamos_vidas {
    grid-template-columns: 32% 58%;
  }
  .salvamos_vidas-img_cont1 {
    left: 35%;
    width: 100%;
  }
  .salvamos_vidas-cont {
    padding: 7% 9% 7% 0;
  }
  .salvamos_vidas-cont1 img {
    width: 2vw;
  }
  .salvamos_vidas-cont2 h1 {
    font-size: 3.7vw;
    margin-top: 5%;
  }
  .salvamos_vidas-cont1 p,
  .salvamos_vidas-cont2 p {
    font-size: 2.1vw;
  }
  .testimonios-img1 {
    top: 2%;
  }
  .testimonios-title-img2 {
    top: -30%;
    width: 30%;
  }
  .testimonios-carousel {
    padding: 5% 3% 15%;
  }
  .testimonios-carousel .owl-nav {
    top: 95%;
    left: 0;
  }
  .flecha-derechaTestimonios,
  .flecha-izquierdaTestimonios {
    font-size: 9vw;
  }
  .testimonios-cuentanos {
    height: 40vh;
    background-size: 100% 100%;
    padding: 10% 0 0 5%;
  }
  .testimonios-cuentanos-title p {
    font-size: 4vw;
  }
  .testimonios-cuentanos-title-img3 {
    right: 20%;
    width: 12%;
  }
  .testimonios-cuentanos-button button {
    font-size: 4vw;
    padding: 0.7% 2.1%;
    margin-top: 9%;
  }
}
</style>