<template>
  <div class="quienes-component">
    <div class="quienes-component-cont">
      <h1>¿Qué <b>hacemos?</b></h1>
      <p>
        <span class="background"><b>Nuestro compromiso</b></span> es trabajar
        incansablemente para garantizar el acceso a las mejores herramientas
        diagnósticas moleculares basadas en la comprensión de nuestros genes.
        Para ello, hemos desarrollado alianzas con los más importantes centros
        de análisis de biología molecular alrededor del mundo para ponerlos al
        alcance de los médicos especialistas en oncología, cardiología,
        inmunología, hematología y genética clínica.
      </p>
      <p>
        <span class="color2"
          >En prevención, complementamos nuestra oferta de valor de los estudios
          genéticos, con la asesoría, seguimiento y gestión de hábitos de vida
          saludables, apoyados en médicos especialistas, genetistas y
          nutriólogos.</span
        >
      </p>
    </div>
    <div class="quienes-component-img">
      <img src="../assets/Assetsx1/Img_Quehacemos.png" draggable="false" />
    </div>
  </div>
</template>


<script>
export default {
  name: "HomeQueHacemos",
};
</script>