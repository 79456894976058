<template>
  <div>
    <div class="home-baner home-baner3">
      <div class="home-baner-img1">
        <img src="../assets/Assetsx1/genethictalkslogo.png" draggable="false" />
      </div>
      <div class="home-baner-img2">
        <img src="../assets/Assetsx1/Circulos.png" draggable="false" />
      </div>
    </div>
    <div class="contenido-video">
      <iframe
        :src="slideContenido"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="contenido-carousel">
      <carousel
        :items="4"
        :margin="300"
        :loop="true"
        :autoplay="true"
        :dots="false"
        :nav="true"
        :navText="[prevContenido, nextContenido]"
        :responsive="{
          0: { margin: 90 },
          400: { margin: 115 },
          700: { margin: 170 },
          1200: { margin: 300 },
          2000: { margin: 350 },
        }"
      >
        <div class="contenido-item">
          <img src="https://i.ytimg.com/vi_webp/unfu6iw5DZg/maxresdefault.webp" @click="changeSlideContenido('https://www.youtube.com/embed/unfu6iw5DZg')">
        </div>
        <div class="contenido-item">
          <img src="https://i.ytimg.com/vi_webp/ODx_98uvmhg/maxresdefault.webp" @click="changeSlideContenido('https://www.youtube.com/embed/ODx_98uvmhg')">
        </div>
        <div class="contenido-item">
          <img src="https://i.ytimg.com/vi_webp/GqCHsm3aS1g/maxresdefault.webp" @click="changeSlideContenido('https://www.youtube.com/embed/GqCHsm3aS1g')">
        </div>
        <div class="contenido-item">
          <img src="https://i.ytimg.com/vi_webp/lxrUaPzbojQ/maxresdefault.webp" @click="changeSlideContenido('https://www.youtube.com/embed/lxrUaPzbojQ')">
        </div>
        <div class="contenido-item">
          <img src="https://i.ytimg.com/vi_webp/unfu6iw5DZg/maxresdefault.webp" @click="changeSlideContenido('https://www.youtube.com/embed/unfu6iw5DZg')">
        </div>
        <div class="contenido-item">
          <img src="https://i.ytimg.com/vi_webp/ODx_98uvmhg/maxresdefault.webp" @click="changeSlideContenido('https://www.youtube.com/embed/ODx_98uvmhg')">
        </div>
        <div class="contenido-item">
          <img src="https://i.ytimg.com/vi_webp/GqCHsm3aS1g/maxresdefault.webp" @click="changeSlideContenido('https://www.youtube.com/embed/GqCHsm3aS1g')">
        </div>
        <div class="contenido-item">
          <img src="https://i.ytimg.com/vi_webp/lxrUaPzbojQ/maxresdefault.webp" @click="changeSlideContenido('https://www.youtube.com/embed/lxrUaPzbojQ')">
        </div>
      </carousel>
    </div>

    <div class="genethinks">
      <div class="genethinks-title">
        <img src="../assets/Assetsx1/Adn_titulos.png" draggable="false" />
        <div class="genethinks-title-imgtitle">
          <img src="../assets/Assetsx1/genethinks.png" draggable="false" />
        </div>
      </div>
      <div class="genethinks-container">
        <carousel
          class="hover-activity"
          :items="3"
          :autoplay="true"
          :touchDrag="false"
          :mouseDrag="false"
          :center="true"
          :loop="true"
          :dots="false"
          :navText="[prevGenethinks, nextGenethinks]"
          :responsive="{
            0: { items: 1, nav: true },
            600: { items: 3, nav: true },
            1000: { items: 3, nav: true },
          }"
          @changed="changed()"
        >
          <div id="card1" class="slider-card">
            <img src="../assets/Assetsx1/Contenido 01.png" draggable="false"/>
          </div>
          <div id="card1" class="slider-card">
            <img src="../assets/Assetsx1/Contenido 02.png" draggable="false"/>
          </div>
          <div id="card1" class="slider-card">
            <img src="../assets/Assetsx1/Contenido 01.png" draggable="false"/>
          </div>
          <div id="card1" class="slider-card">
            <img src="../assets/Assetsx1/Contenido 02.png" draggable="false"/>
          </div>
        </carousel>
      </div>
    </div>
    <div class="footer-contenido">
      <div class="portafolio-img4">
        <img src="../assets/Assetsx1/Back002.png" />
      </div>
      <div class="genethic_talks-download-button">
        <a :href="pdf" download >
          <button>Descargar</button>
        </a>
      </div>
      <div class="footer-logos footer-logosS">
        <div>
          <img src="../assets/Assetsx1/cuidarte.png" draggable="false" />
        </div>
        <div>
          <img src="../assets/Assetsx1/Sohin.png" draggable="false" />
        </div>
        <div>
          <img src="../assets/Assetsx1/Concierge.png" draggable="false" />
        </div>
        <div>
          <img
            src="../assets/Assetsx1/HARMONY-HEALTH-VERTICAL.png"
            draggable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  name: "Contenido",
  data() {
    return {
      prevContenido:
        '<i class="fas fa-chevron-left flecha-izquierdaContenido"></i>',
      nextContenido:
        '<i class="fas fa-chevron-right flecha-derechaContenido"></i>',
      prevGenethinks:
        '<i class="far fa-arrow-alt-circle-left flecha-izquierdaGenethinks"></i>',
      nextGenethinks:
        '<i class="far fa-arrow-alt-circle-right flecha-derechaGenethinks"></i>',
      //pdf: "https://genethic.mx/PDF/Nutri_OK.pdf",
      pdf: "../PDF/Nutri_OK.pdf",
      slideContenido: "https://www.youtube.com/embed/unfu6iw5DZg"
    };
  },
  methods: {
    changed(){
      //this.pdf == "https://genethic.mx/PDF/Nutri_OK.pdf" ? this.pdf = "https://genethic.mx/PDF/Mammaprint_OK.pdf" : this.pdf = "https://genethic.mx/PDF/Nutri_OK.pdf";
      this.pdf == "../PDF/Nutri_OK.pdf" ? this.pdf = "../PDF/Mammaprint_OK.pdf" : this.pdf = "../PDF/Nutri_OK.pdf";
    },
    changeSlideContenido(link){
        this.slideContenido = link;
    }
  },
  components: { carousel },
};
</script>

<style>
.home-baner3 {
  position: relative;
  height: 46vw;
  background-image: url("../assets/Assetsx1/Header03.png");
  background-repeat: no-repeat;
  background-size: 100% 46vw;
}
.home-baner-img1 {
  display: flex;
  align-items: center;
  margin-left: 5%;
  width: 42%;
  height: 100%;
}
.home-baner-img2 {
  position: absolute;
  width: 20%;
  bottom: 0;
  left: 0;
}
.home-baner-img1 img,
.home-baner-img2 img {
  width: 100%;
}
.contenido-video {
  background-color: #134270;
  position: relative;
  text-align: center;
  padding: 2% 0;
}
.contenido-video iframe {
  width: 55%;
  height: 33vw;
  background-color: #000;
  border-radius: 15px;
}
.contenido-carousel {
  padding: 5% 3%;
}
.contenido-item {
  background-color: #134270;
  border-radius: 15px;
  width: 25vw;
  height: 14vw;
  cursor: pointer;
}
.contenido-item img{
  width: 100%;
  border-radius: 15px;
}
.owl-theme .owl-nav [class*="owl-"] {
  background-color: transparent;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
}
.contenido-carousel .owl-nav {
  position: absolute;
  top: -38%;
  right: 0%;
}
.flecha-izquierdaContenido {
  color: #1cb7c7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.flecha-derechaContenido {
  color: #1cb7c7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.genethinks {
  box-shadow: 0 -18px 20px 0 rgba(31, 38, 135, 0.37);
}
.genethinks-title {
  position: relative;
  text-align: center;
  padding-top: 2%;
  margin-bottom: 5%;
}
.genethinks-title img {
  width: 100%;
}
.genethinks-title-imgtitle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 45%;
}
.genethinks-container {
  position: relative;
  z-index: 2;
}
.genethinks-container .owl-nav {
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  top: 50%;
  right: 0%;
  width: 100%;
  height: fit-content;
}
.flecha-izquierdaGenethinks {
  position: relative;
  z-index: 3;
  color: #1cb7c7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.flecha-derechaGenethinks {
  color: #1cb7c7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.owl-item.active.center .slider-card img {
  transform: scale(1);
  opacity: 1;
  background: #ffffff;
  color: #fff;
}
.hover-activity img {
  background-color: #ffffff;
  box-shadow: 0px 15px 45px -25px rgba(206, 191, 191, 0.493);
  transform: scale(0.7);
  opacity: 0.4;
  transition: all 0.3s;
}
.footer-contenido {
  position: relative;
  height: 20vw;
}
.genethic_talks-download-button {
  text-align: center;
  position: relative;
  z-index: 1;
}
.genethic_talks-download-button button {
  background-color: #e8f8f9;
  color: #1cb7c7;
  font-family: "Mulish", sans-serif;
  font-size: 1.7vw;
  border: #1cb7c7 solid 2px;
  border-radius: 7px;
  width: fit-content;
  padding: 0.7% 2.1%;
  cursor: pointer;
  margin-top: 2%;
}
@media (max-width: 400px) {
  .contenido-video iframe {
    width: 95%;
    height: 25vh;
  }
  .contenido-carousel {
    padding: 5% 3% 15%;
  }
  .contenido-carousel .owl-nav {
    top: 95%;
    left: 0;
  }
  .genethinks-container .owl-nav {
    bottom: -8%;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    top: auto;
  }
  .flecha-derechaContenido,
  .flecha-izquierdaContenido,
  .flecha-izquierdaGenethinks,
  .flecha-derechaGenethinks {
    font-size: 9vw;
  }
  .genethic_talks-download-button button {
    font-size: 4vw;
    padding: 0.7% 2.1%;
    margin-top: 9%;
  }
  .footer-contenido {
    height: 75vw;
  }
}
</style>