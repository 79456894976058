<template>
  <div id="app">
    <header>
      <div class="logo">
        <router-link to="/home" @click.native="scrollTop()"
          ><img src="./assets/Assetsx1/1.Genetic.png" draggable="false"
        /></router-link>
      </div>
      <a id="toggleB" @click="toggle()"><i class="fas fa-bars"></i></a>
      <transition name="slide">
        <nav v-if="toggleActive">
          <div class="items">
            <ul class="items-main_items">
              <li class="item">
                <router-link
                  to="/home"
                  active-class="active-header"
                  class="header-mennu-link"
                  @click.native="scrollTop()"
                  >Home</router-link
                >
                <!-- <ul class="items-sub_items si-1">
                  <li class="item"><a href="#nav-servicios">+ Servicios</a></li>
                  <li class="item"><a href="#nav-gente_sohin">+ Gente Sohin</a></li>
                  <li class="item"><a href="#nav-certificaciones">+ Certificaciones</a></li>
                  <li class="item"><a href="#nav-prensa">+ Notas</a></li>
                </ul> -->
              </li>
              <li class="item">
                <router-link
                  to="/servicios"
                  active-class="active-header"
                  class="header-mennu-link"
                  @click.native="scrollTop()"
                  >Servicios</router-link
                >
                <!-- <ul class="items-sub_items si-2">
                  <li class="item"><a href="#nav-cartas">+ Carta</a></li>
                  <li class="item"><a href="#nav-mision">+ Misión</a></li>
                  <li class="item"><a href="#nav-vision">+ Visión</a></li>
                  <li class="item"><a href="#nav-diez">+ Valores</a></li>
                </ul> -->
              </li>
              <li class="item">
                <router-link
                  to="/contenido"
                  active-class="active-header"
                  class="header-mennu-link"
                  @click.native="scrollTop()"
                  >Contenido</router-link
                >
              </li>
              <li class="item">
                <router-link
                  to="/contacto"
                  active-class="active-header"
                  class="header-mennu-link"
                  >Contacto</router-link
                >
              </li>

              <li class="item item-pago">
                <button @click="redirectTo()">Pago</button>
              </li>
            </ul>
          </div>
        </nav>
      </transition>
    </header>
    <router-view></router-view>
    <footer>
      <img
        src="./assets/Assetsx1/GENETHIC-SERVICES-BLANCO.png"
        draggable="false"
      />
      <hr />
      <div class="footeer-menu">
        <router-link
          to="/home"
          active-class="active-footer"
          class="footer-mennu-link"
          @click.native="scrollTop()"
          >Home</router-link
        >
        <router-link
          to="/contenido"
          active-class="active-footer"
          class="footer-mennu-link"
          @click.native="scrollTop()"
          >Contenido</router-link
        >
        <a
          href="mailto:infogenetics@sohin.mx"
          target="_blank"
          rel="noopener noreferrer"
          data-auth="NotApplicable"
          class="footer-mennu-link footer-mennu-link-statics"
          >infogenetics@sohin.mx</a
        >
        <router-link
          to="/servicios"
          active-class="active-footer"
          class="footer-mennu-link"
          @click.native="scrollTop()"
          >Servicios</router-link
        >
        <router-link
          to="/contacto"
          active-class="active-footer"
          class="footer-mennu-link"
          @click.native="scrollTop()"
          >Contacto</router-link
        >
        <a
          href="tel:+525541251110"
          class="footer-mennu-link footer-mennu-link-statics"
          >+52 55 4125 1110</a
        >
      </div>
      <div class="footer-contacto">
        <div>
          <img
            src="./assets/Assetsx1/fb.png"
            draggable="false"
            @click="socialNet('https://www.facebook.com/GenethicServices')"
          />
        </div>
        <div>
          <img
            src="./assets/Assetsx1/ig.png"
            draggable="false"
            @click="
              socialNet('https://www.instagram.com/genethic_services/?hl=es')
            "
          />
        </div>
        <div>
          <img
            src="./assets/Assetsx1/tw.png"
            draggable="false"
            @click="socialNet('https://twitter.com/GenethicServic1')"
          />
        </div>
        <div>
          <img
            src="./assets/Assetsx1/lnk.png"
            draggable="false"
            @click="socialNet('https://www.linkedin.com/company/genethic-services/')"
          />
        </div>
      </div>
      <div class="footer-social">
        <p>
          Guillermo González Camarena 1450 piso 9 Santa Fe, Álvaro Obregón,
          CDMX, 01210
        </p>
        <br />
        <router-link to="/aviso-de-privacidad">
          <p @click="scrollTop()">AVISO DE PRIVACIDAD</p>
        </router-link>
      </div>
      <img
        class="footer-img"
        src="./assets/Assetsx1/TexturaFooter.png"
        draggable="false"
      />
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    window.screen.width < 600
      ? (this.toggleActive = false)
      : (this.toggleActive = true);
  },
  data() {
    return {
      toggleActive: true,
    };
  },
  mounted(){
    //this.$router.push('/servicios');/*
    if(this.$route.params.link == 'contenido') this.$router.push('/contenido');
    if(this.$route.params.link == 'contacto') this.$router.push('/contacto');
    if(this.$route.params.link == 'aviso-de-privacidad') this.$router.push('/aviso-de-privacidad');
    console.log(this.$route.params.link);//*/
  },
  methods: {
    toggle() {
      const elm = document
        .getElementById("toggleB")
        .getElementsByTagName("i")[0];
      if (elm.className == "fas fa-bars") {
        elm.setAttribute("class", "fas fa-times");
        this.toggleActive = true;
      } else {
        elm.setAttribute("class", "fas fa-bars");
        this.toggleActive = false;
      }
    },
    redirectTo() {
      window.location = "https://genethic.pagocliente.mx/";
    },
    socialNet(link) {
      window.open(link, "_blank");
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
* {
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
header {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 50;
  position: sticky;
  box-shadow: 0 1px 20px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(4px);
}
.logo {
  width: 10%;
  cursor: pointer;
  margin-left: 4%;
}
.logo img {
  width: 100%;
}
#toggleB {
  color: #134270;
  text-decoration: none;
  display: none;
}
nav {
  margin-right: 4%;
  width: 40%;
}
.items-main_items,
.items-sub_items {
  list-style-type: none;
}
.items-main_items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0%;
}
/* .items-sub_items{
    position: absolute;
    background: #134270;
    visibility: hidden;
    line-height: 2;
    padding: 5px 0;
    width: 12%;
  } */
.header-mennu-link {
  font-family: "Mulish", sans-serif;
  color: #c6c6c6;
  text-decoration: none;
  font-size: 1vw;
}
.active-header {
  color: #134270;
}
.item-pago button {
  font-family: "Montserrat", sans-serif;
  background-color: #1cb7c71a;
  color: #1cb7c7;
  border: #1cb7c7 solid 1px;
  border-radius: 2px;
  padding: 5px 2rem;
  cursor: pointer;
  font-size: 1vw;
}
/* .items-main_items .item a{
    margin-right: 40px;
  } */
/* .items-sub_items .item a{
    color: #fff;
    margin: 10px 10px;
  } */
.header-mennu-link:hover {
  text-decoration: underline;
}
.color {
  color: #1cb7c7;
}
.color2 {
  color: #71c9d4;
}
.background {
  background-color: #8ddbe3;
}

.footer-logos {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 15%);
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.footer-logos img {
  width: 100%;
}
footer {
  background-color: #134270;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 0 4%;
}
footer img {
  width: 20%;
}
footer hr {
  color: #1cb7c7;
  width: 90%;
  margin: 2% 0;
}
.footeer-menu {
  display: grid;
  grid-template-columns: repeat(3, 25%);
  grid-template-rows: repeat(2, 7vh);
  justify-content: space-between;
  width: 100%;
  text-align: center;
  align-items: center;
}
.footer-mennu-link {
  color: #fff;
  text-decoration: none;
  font-family: "Mulish", sans-serif;
  font-size: 1.2vw;
}
.footer-mennu-link-statics,
.active-footer {
  color: #1cb7c7;
}
.footer-contacto {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 35%;
  margin: 4% 0 3%;
}
.footer-contacto img {
  width: 100%;
  cursor: pointer;
}
.footer-social {
  color: #fff;
  position: relative;
  z-index: 2;
  font-family: "Mulish", sans-serif;
  font-size: 1.2vw;
  text-align: center;
}
.footer-social a {
  color: #fff;
  text-decoration: none;
}
.footer-img {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 0;
}
@media (max-width: 400px) {
  .logo {
    width: 25%;
  }
  #toggleB {
    display: flex;
    margin-right: 7%;
  }
  nav {
    width: 100%;
    margin: 0;
    margin-bottom: 4%;
  }
  .items-main_items {
    flex-direction: column;
    line-height: 1.7;
  }
  .items-main_items li {
    flex-wrap: wrap;
  }
  .header-mennu-link,
  .item-pago button {
    font-size: 4vw;
  }
  .slide-enter-active {
    transition: all 0.4s linear;
  }
  .slide-leave-active {
    transition: all 0.3s linear;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translatey(-20px);
  }
  .footer-logos {
    bottom: 4%;
    grid-template-columns: repeat(2, 35%);
    width: 80%;
  }
  footer {
    padding: 5% 0 8%;
  }
  footer img {
    width: 50%;
  }
  .footeer-menu {
    grid-template-columns: repeat(3, 33%);
    grid-template-rows: repeat(2, 4vh);
  }
  .footer-mennu-link,
  .footer-social {
    font-size: 2.7vw;
  }
  .footer-contacto {
    width: 70%;
    margin: 4% 0 3%;
  }
  .footer-contacto img {
    width: 50%;
  }
}
@media (min-width: 1810px) {
  .logo {
    width: 8%;
  }
}
</style>
