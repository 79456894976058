<template>
  <div id="contact">
    <div class="contacto">
      <div class="contacto-img">
        <img src="../assets/Assetsx1/Contacto.png" draggable="false" />
      </div>
      <div class="contacto-form">
        <form @submit.prevent="sendForm()">
          <h1>Contacto</h1>
          <hr />
          <div class="form-col1">
            <input
              type="text"
              v-model="contact.name"
              placeholder="Nombre completo"
              required
            /><br />
          </div>
          <div class="form-col1">
            <p>Por favor seleccione una opción</p>
            <div class="contacto-form-radio">
              <input
                type="radio"
                v-model="contact.people"
                value="paciente"
                id="paciente"
                checked
              />
              <label for="paciente">Paciente</label>
              <input
                type="radio"
                v-model="contact.people"
                value="medico"
                id="medico"
              />
              <label for="medico">Médico</label>
              <input
                type="radio"
                v-model="contact.people"
                value="familiar"
                id="familiar"
              />
              <label for="familiar">Familiar</label>
              <input
                type="radio"
                v-model="contact.people"
                value="otro"
                id="otro"
              />
              <label for="otro">Otro</label>
            </div>
          </div>
          <div class="form-col2">
            <input
              type="email"
              v-model="contact.email"
              placeholder="Correo electrónico"
              required
            />
            <input
              type="tel"
              v-model="contact.phone"
              placeholder="Teléfono"
              required
            />
            <select id="country_select" @change="changeCountry()" required>
              <option hidden selected>Seleccione su país</option>
              <option
                v-for="country in countries"
                :key="country"
                :value="country"
              >
                {{ country }}
              </option>
            </select>
            <input
              type="text"
              v-model="contact.state"
              placeholder="Ingrese su estado"
              required
            />
          </div>
          <div class="form-col1">
            <textarea
              v-model="contact.message"
              placeholder="Mensaje"
              rows="7"
              required
            ></textarea>
            <div class="form-submit">
              <input type="submit" value="Enviar" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Test alert -->
    <!-- <button style="position: absolute; z-index: 3" @click="testAlert()">
      test alert
    </button>
    <input style="position: absolute; z-index: 3" type="text" v-model="alert" /> -->
    <div v-show="alertShowCont" id="alert">
      <div v-if="alert.length == 0" class="load">
        <div class="load1"></div>
        <div class="load2"></div>
      </div>
      <div id="alert-cont">
        <i id="alert-icon"></i>
        <p id="alert-global">
          <b>{{ alert }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contacto",
  data() {
    return {
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua & Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia & Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Cape Verde",
        "Cayman Islands",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D Ivoire",
        "Croatia",
        "Cruise Ship",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Polynesia",
        "French West Indies",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kuwait",
        "Kyrgyz Republic",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Namibia",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Pierre & Miquelon",
        "Samoa",
        "San Marino",
        "Satellite",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "South Africa",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "St Kitts & Nevis",
        "St Lucia",
        "St Vincent",
        "St. Lucia",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        `Timor L'Este`,
        "Togo",
        "Tonga",
        "Trinidad & Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks & Caicos",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (US)",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      contact: {
        name: "",
        people: "",
        email: "",
        phone: "",
        country: "",
        state: "",
        message: "",
      },
      alertShowCont: false,
      alert: "",
    };
  },
  methods: {
    sendForm() {
      console.log(this.contact);
      this.alertShowCont = true;
      axios
        .post(
          "https://localhost/api/mailSMTP/sendmailContact.php",
          this.contact
        )
        /*axios
        .post(
          "https://genethic.pagocliente.mx/api/mailSMTP/sendmailContact.php",
          this.contact
        )*/
        .then((data) => {
          this.alertShow(data.data, 4000);
          console.log(data);
          this.clearForm();
        })
        .catch((err) => {
          this.alertShow(err, 3000);
          console.log(err);
        });
    },
    changeCountry() {
      this.contact.country = document.getElementById("country_select").value;
    },
    clearForm(){
      this.contact.name = "";
      this.contact.people = "";
      this.contact.email = "";
      this.contact.phone = "";
      this.contact.country = "";
      this.contact.state = "";
      this.contact.message = "";
    },
    alertShow(response, time) {
      const alertIcon = document.getElementById("alert-icon");

      if (response == "") {
        alertIcon.setAttribute("class", "far fa-check-circle");
        this.alert = "Formulario enviado";
        setTimeout(() => {
          this.alertShowCont = false;
          alertIcon.removeAttribute("class");
          this.alert = "";
        }, time);
      } else {
        alertIcon.setAttribute("class", "fas fa-exclamation-triangle");
        this.alert = response;
        setTimeout(() => {
          this.alertShowCont = false;
          alertIcon.removeAttribute("class");
          this.alert = "";
        }, time);
      }
    },
    /* Test alert */
    /*testAlert() {
      this.alertShowCont
        ? (this.alertShowCont = false)
        : (this.alertShowCont = true);
    },*/
  },
};
</script>

<style>
#contact {
  position: relative;
}
.contacto {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 5%;
  border-radius: 20px;
  box-shadow: 0px 3px 15px -3px #1f26875e;
}
.contacto-img img {
  border-radius: 20px 0 0 20px;
  width: 100%;
  height: 100%;
}
form {
  color: #00416a;
  font-size: 1vw;
  font-family: "Mulish", sans-serif;
}
input,
textarea {
  width: 94%;
  border-radius: 5px;
  background-color: #e8ecf1;
  color: #5c859f;
  border: none;
  padding: 1.5vh 0 1.5vh 5%;
  margin: 1.5vh 0;
  font-family: "Mulish", sans-serif;
  resize: none;
}
input::placeholder,
textarea::placeholder {
  font-family: "Mulish", sans-serif;
  color: #5c859f;
  opacity: 1;
}
select {
  padding: 1.5vh 0 1.5vh 5%;
  margin: 1.5vh 0;
  width: 100%;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 5px 0px #1f26875e;
  background-color: #fff;
  color: #5c859f;
}
input[type="submit"] {
  padding: 1.5vh 0 1.5vh;
  cursor: pointer;
  background-color: #1cb7c7;
  color: #fff;
  font-size: 1vw;
  width: 25%;
}
.contacto-form {
  padding: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contacto-form h1 {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 2.2vw;
}
.contacto-form hr {
  border: #134270 solid 0.5px;
  margin: 1.5vh 0;
}
.form-col1 {
  display: grid;
  grid-template-columns: 100%;
}
.form-col2 {
  display: grid;
  grid-template-columns: 46% 46%;
  justify-content: space-between;
}
.contacto-form-radio {
  display: flex;
  width: 60%;
  align-items: center;
  margin: 1.5vh 0;
}
.form-submit {
  text-align: center;
  width: 100%;
  margin-top: 2.5vh;
}
#alert {
  background-color: rgba(245, 245, 245, 0.8);
  position: absolute;
  top: 0%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  margin: auto;
  z-index: 2;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
#alert-cont {
  background-color: #fff;
  position: absolute;
  top: 0%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  margin: auto;
  z-index: 3;
  width: 35vw;
  height: fit-content;
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 15px -3px #1f26875e;
  border-radius: 20px;
}
.load {
  position: relative;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  margin: auto;
  z-index: 4;
  width: 100%;
  height: 100%;
}
.load1 {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  margin: auto;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  border: solid 10px transparent;
  border-top: solid 10px #214d78;
  border-left: solid 10px #214d78;
  animation: loader1 1.5s linear infinite;
}
.load2 {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  margin: auto;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: solid 10px transparent;
  border-top: solid 10px #1cb7c7;
  border-left: solid 10px #1cb7c7;
  animation: loader2 1.5s linear infinite;
}
#alert p {
  padding: 1.5vh 0 1.5vh 5%;
  margin: 1.5vh 0;
  font-size: 2vw;
  color: #214d78;
  font-family: "Mulish", sans-serif;
}
#alert i {
  padding: 1.5vh 0 1.5vh 5%;
  margin: 1.5vh 0;
  font-size: 2.5vw;
  color: #214d78;
}

@keyframes loader1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@media (max-width: 400px) {
  .contacto {
    grid-template-columns: 100%;
  }
  .contacto-img {
    display: none;
  }
  form {
    font-size: 3vw;
  }
  input,
  textarea,
  input::placeholder,
  textarea::placeholder,
  select {
    font-size: 3vw;
  }
  input[type="submit"] {
    font-size: 4vw;
    width: 35%;
  }
  .contacto-form h1 {
    font-size: 4vw;
  }
  .contacto-form-radio {
    width: 95%;
  }
  #alert-cont {
    width: 80vw;
  }
  #alert p {
    font-size: 7vw;
  }
  #alert i {
    font-size: 10vw;
  }
}
</style>