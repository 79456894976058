<template>
  <div class="quienes-component">
    <div class="quienes-component-cont">
      <h1>¿Cómo <b>funciona?</b></h1>
      <p>
        <span class="background"
          ><b>Los médicos y profesionales de la salud</b></span
        >
        utilizan los estudios genéticos y genómicos para entender las diversas
        características que tiene un paciente o de una enfermedad compleja, lo
        que permite establecer un diagnóstico, pronóstico, tratamiento y
        programa de prevención personalizados y de alta precisión.
      </p>
    </div>
    <div class="quienes-component-img">
      <img src="../assets/Assetsx1/Img_Comofunciona.png" draggable="false" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeComoFunciona",
};
</script>