import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Home from './components/Home.vue'
import HomeComoFunciona from './components/Home-comoFunciona.vue'
import HomeQueHacemos from './components/Home-queHacemos.vue'
import HomeQuienesSomos from './components/Home-quienesSomos.vue'

import Servicios from './components/Servicios.vue'
import Contenido from './components/Contenido.vue'
import Contacto from './components/Contacto.vue'
import Aviso from './components/Aviso.vue'

Vue.config.productionTip = false

Vue.use(VueRouter);

const routes = [
  {path: '/', component: Home, children: [
    {path: '/', component: HomeQuienesSomos},
  ]},
  {path: '/home', component: Home, children: [
    {path: '/', component: HomeQuienesSomos},
    {path: '/como-funciona', component: HomeComoFunciona},
    {path: '/que-hacemos', component: HomeQueHacemos},
    {path: '/quienes-somos', component: HomeQuienesSomos},
  ]},
  {path: '/servicios/:id?', component: Servicios},
  {path: '/contenido', component: Contenido},
  {path: '/contacto', component: Contacto},
  {path: '/aviso-de-privacidad', component: Aviso},
  //{path: '*', component: ErrorComponent}

];

const router = new VueRouter({
  routes,
  mode: 'history'
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
